import React from "react";

function Logo(props) {
  return (
    <h1 className="app-logo">
      <em>love</em> actually<em>.</em>works
    </h1>
  );
}

export default Logo;
